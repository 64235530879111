import i18n from "@/i18n";
import { markRaw, toRaw } from "vue";
import {
  Plus,
  Delete,
  BottomLeft,
  TopRight,
  Search,
  Download,
  Edit,
} from "@element-plus/icons-vue";
import api from "@/api";
import moment from "moment";
import { getUploadUrl } from "../../utils/tools";
const { t } = i18n.global;
export default {
  tableCols: [
    {
      name: t("sims.cName"),
      filed: "cName",
    },
    {
      name: t("sims.sNo"),
      filed: "sNo",
    },
    {
      name: t("sims.sMonthFlow"),
      filed: "sMonthFlow",
    },
    {
      name: t("sims.sMonthDay"),
      filed: "sMonthDay",
    },
    {
      name: t("sims.syll"),
      filed: "syll",
    },
    {
      name: t("sims.syllm"),
      filed: "syllm",
    },
  ],
  tableOps: [
    // {
    //   opType: "btn",
    //   icon: markRaw(Edit),
    //   name: t("commKey.Edit"),
    //   type: "",
    //   click: (row, callback) => {
    //     api.sys_role_row({ id: row.id }).then((res) => {
    //       // console.log(res.data.row, "11");
    //       let _row = res.data.row;
    //       callback({ type: "edit", row: _row });
    //     });
    //   },
    // },
    // {
    //   opType: "del",
    //   icon: markRaw(Delete),
    //   name: t("commKey.Delete"),
    //   type: "danger",
    //   click: (row, callback) => {
    //     callback({ type: "delete", row: row });
    //     // activeKey.value = "test";
    //   },
    // },
  ],
  searchCols: [
    {
      type: "ipt",
      title: t("commKey.keyname"),
      key: "keyname",
      val: "",
    },

    {
      type: "btn",
      btnType: "success",
      icon: markRaw(Search),
      title: t("commKey.Search"),
      click: (callback) => {
        callback({ type: "search" });
      },
    },
    // {
    //   type: "btn",
    //   btnType: "primary",
    //   title: t("commKey.Add"),
    //   icon: markRaw(Plus),
    //   click: (callback) => {
    //     callback({ type: "add", row: { id: 0 } });
    //   },
    // },
  ],
  formCols: [
    {
      type: "sel",
      label: t("roles.cName"),
      field: "rCompanyID",
      placeholder: t("roles.cName"),
      val: "",
      required: true,
    },
    {
      type: "ipt",
      label: t("roles.rName"),
      field: "rName",
      placeholder: t("roles.rName"),
      val: "",
      required: false,
    },
  ],
};
